<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="screenRef center-screen" #screenRef>
  <div class="container-fluid">
    <p>
    <h5>Client is PIP/PEP and an email will be sent to Compliance. Please complete this form.</h5>
    <div class="row">
      <div class="col-12 mb-2">
        <div class="card rounded-1 pt-2">
          <div class="idlight ms-auto me-auto rounded-2 shadow-2-strong justify-content-center my-3 p-2"
            style="width: 900px;">
            <form [formGroup]="agentComplianceNotificationForm">
              <table class="table table-striped table-light table-hover table-sm lms-table__grid caption-top"
                style="width: 875px; margin:auto; text-align: start; vertical-align: bottom;">
                <caption>Client Detials: {{preAuthorisationModel.quoteId}}</caption>
                <tbody>
                  <tr>
                    <td style="width: 25%;">Full names</td>
                    <td>
                      {{preAuthorisationModel.fullNames}}
                    </td>

                    <td>Surname</td>
                    <td>
                      {{preAuthorisationModel.lastName}}
                    </td>
                  </tr>

                  <tr>
                    <td>ID Number</td>
                    <td>
                      {{preAuthorisationModel.idNumber}}
                    </td>

                    <td>Risk Score</td>
                    <td>
                      {{preAuthorisationModel.riskScore}}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table class="table table-striped table-light table-hover table-sm lms-table__grid caption-top"
                style="width: 875px; margin:auto; text-align: start; vertical-align: bottom;">
                <tbody>
                  <tr>
                    <td>Occupation</td>
                    <td>
                      <input class="form-control ms-0" placeholder="Occupation" formControlName="occupation" autofocus
                        required
                        (keypress)="($event.charCode <= 93 && $event.charCode >= 65 || $event.charCode == 32) || ($event.charCode <= 122 && $event.charCode >= 97)">
                      <div
                        *ngIf="agentComplianceNotificationForm.controls['occupation']?.errors && (agentComplianceNotificationForm.controls['occupation']?.dirty || agentComplianceNotificationForm.controls['occupation']?.touched)">
                        <label mdbLabel class="error-label"> Occupation is required.</label>
                      </div>
                    </td>

                    <td>Gender</td>
                    <td>
                      <select class="form-control ms-0" formControlName="gender" required autofocus>
                        <option *ngFor="let genders of lstGenders" [value]="genders.value">
                          {{genders.description}}</option>
                      </select>
                      <div
                        *ngIf="agentComplianceNotificationForm.controls['gender']?.errors && (agentComplianceNotificationForm.controls['gender']?.dirty || agentComplianceNotificationForm.controls['gender']?.touched)">
                        <label mdbLabel class="error-label"> Gender is required.</label>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>Marital Status</td>
                    <td>
                      <select class="form-control ms-0" formControlName="maritalStatus" required autofocus>
                        <option *ngFor="let maritalStatus of lstMaritalStatus" [value]="maritalStatus.value">
                          {{maritalStatus.description}}</option>
                      </select>
                      <div
                        *ngIf="agentComplianceNotificationForm.controls['maritalStatus']?.errors && (agentComplianceNotificationForm.controls['maritalStatus']?.dirty || agentComplianceNotificationForm.controls['maritalStatus']?.touched)">
                        <label mdbLabel class="error-label"> Maritial Status is required.</label>
                      </div>
                    </td>

                    <!-- <td>Nationality</td>
                    <td>
                      <select class="form-control ms-0" formControlName="nationality" required autofocus
                        (change)="onNationalitySelect($event)">
                        <option *ngFor="let nationality of lstNationality" [value]="nationality.value">
                          {{nationality.description}}</option>
                      </select>
                      <div
                        *ngIf="agentComplianceNotificationForm.controls['nationality']?.errors && (agentComplianceNotificationForm.controls['nationality']?.dirty || agentComplianceNotificationForm.controls['nationality']?.touched)">
                        <label mdbLabel class="error-label"> Nationality is required.</label>
                      </div>
                    </td> -->
                  </tr>

                  <tr>
                    <td>Address line 1</td>
                    <td>
                      <input class="form-control ms-0" placeholder="Address line 1" formControlName="adressLine1"
                        autofocus required (keypress)="omit_special_char($event)">
                      <div
                        *ngIf="agentComplianceNotificationForm.controls['adressLine1']?.errors && (agentComplianceNotificationForm.controls['adressLine1']?.dirty || agentComplianceNotificationForm.controls['adressLine1']?.touched)">
                        <label mdbLabel class="error-label"> Address is required.</label>
                      </div>
                    </td>

                    <td>Address line 2</td>
                    <td>
                      <input class="form-control ms-0" placeholder="Address line 2" formControlName="adressLine2"
                        autofocus (keypress)="omit_special_char($event)">
                    </td>
                  </tr>

                  <tr>
                    <td>Suburb</td>
                    <td>
                      <input class="form-control ms-0" formControlName="suburb" placeholder="Suburb" autofocus required
                        (keypress)="($event.charCode <= 93 && $event.charCode >= 65 || $event.charCode == 32) || ($event.charCode <= 122 && $event.charCode >= 97)"
                        (keypress)="omit_special_char($event)">
                      <div
                        *ngIf="agentComplianceNotificationForm.controls['suburb']?.errors && (agentComplianceNotificationForm.controls['suburb']?.dirty || agentComplianceNotificationForm.controls['suburb']?.touched)">
                        <label mdbLabel class="error-label"> Suburb is required.</label>
                      </div>
                    </td>

                    <td>Town / City</td>
                    <td>
                      <input class="form-control ms-0" placeholder="Town" formControlName="town" autofocus required>
                      <div
                        *ngIf="agentComplianceNotificationForm.controls['town']?.errors && (agentComplianceNotificationForm.controls['town']?.dirty || agentComplianceNotificationForm.controls['town']?.touched)">
                        <label mdbLabel class="error-label"> Town is required.</label>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>Area code</td>
                    <td>
                      <input class="form-control ms-0" maxlength="4" placeholder="Area code"
                        formControlName="areaCode" autofocus required
                        (keypress)="($event.charCode >= 48 && $event.charCode < 58)">
                      <div
                        *ngIf="agentComplianceNotificationForm.controls['areaCode']?.errors && (agentComplianceNotificationForm.controls['areaCode']?.dirty || agentComplianceNotificationForm.controls['areaCode']?.touched)">
                        <label mdbLabel class="error-label"> Area Code is required.</label>
                      </div>
                    </td>

                    <td>Province</td>
                    <td>
                      <select class="form-control ms-0" formControlName="province" autofocus required>
                        <option *ngFor="let province of lstProvinces" [value]="province.value">{{province.description}}
                        </option>
                      </select>
                      <div
                        *ngIf="agentComplianceNotificationForm.controls['province']?.errors && (agentComplianceNotificationForm.controls['province']?.dirty || agentComplianceNotificationForm.controls['province']?.touched)">
                        <label mdbLabel class="error-label"> Province is required.</label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
            <br>
            <button mat-raised-button class="btn btn-primary rounded-1" mdbRipple type="button" color="primary"
              [textContent]="'Submit'" (click)="submit()">
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>