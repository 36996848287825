import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  Validators
} from "@angular/forms";
import {
  distinctUntilChanged,
  Observable,
  of,
  Subject,
  Subscription,
  tap
} from "rxjs";
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BranchEmployerServiceNew } from "src/app/features/service/branch-employer.service";
import { UserService } from "src/app/core/services/lmsuser.service";
import { liveSearch } from "src/app/shared/live-search.operator";
import { BranchEmployer } from "../models/branch-employers";
import { EmployerDetailsModel } from "../models/employer-details-model";
import { BranchEmployerService } from "src/app/core/services/branch-employer.service";
import { LMSSharedUiModule } from "@lmsSharedUI";


import { IsLoadingService } from "src/app/features/branch-employers/is-loading.service";
import { MatButtonModule } from '@angular/material/button';
// import { split } from "cypress/types/lodash";


@Component({
  selector: "app-branch-employers-search",
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatProgressBarModule,
    LMSSharedUiModule],
  providers: [
  ],
  templateUrl: "./basic-employer-form.component.html",
  standalone: true,
  styleUrls: ["./basic-employer-form.component.css"],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasicEmployersSearchComponent implements OnInit, OnDestroy,OnChanges {
  @Input() dontAdd = false;
  @Input() searchValue = '';
  @Input() debounceTime = 500;
  @Input() showAddButton = false;

  @Output() textChange = new EventEmitter<string>();
  @Input() frmControlName!: string;

  @Output() addnewRequired = new EventEmitter<boolean>();
  @Output() empSelected = new EventEmitter<any>();

  employersearchFormGroup: FormGroup;
  originalFormState: EmployerDetailsModel;

  branchEmployers: BranchEmployer[] = [
    {
      employerName: "Loading...",
      id: -1,
      email: "",
      paydate: 4,
      shiftingRule: "",
      tel1: "",
      wageFrequency: ""
    }
  ];
  filteredEmployers: Observable<BranchEmployer[]> = of(this.branchEmployers);
  bEmployers: BranchEmployer[];
  suggestions$!: Observable<BranchEmployer>;
  quickSEarchIsPending$: Observable<boolean>;
  selectedLst = {
    employerName: "",
    id: -1,
    email: "",
    paydate: 4,
    shiftingRule: "",
    tel1: "",
    wageFrequency: ""
  };

  selectedLst2 = {
    id: 0,
    employerName: '',
    tnumber: 0,
    name: ''
  };

  newEmployer = false;
  submitted = false;
  title = "lms-text-search-highlight";
  inputwdith = 200;
  subscriptions: Subscription[] = [];

  // AllfilteredEmployers: any[];
  // _employerListFilter: string;

  employerNameSubject = new Subject<string>();
  regions: string[] = ["Africa", "Americas", "Asia", "Europe", "Oceania"];
  readonly employer$ =
    this.employerNameSubject.pipe(
      // ignore new term if same as previous term
      distinctUntilChanged(),
      tap((sdata) => (this.newEmployer ||= (sdata && sdata.length > 0) || false)),
      // switch to new search observable each time the term changes
      liveSearch((value: string) =>
        //this.getBranchEmployerObs().pipe(
        this.isLoadingService.add(
          this.branchEmployersService.quickSearchBranchEmployers({ ename: value, bserial: this._branchSerial || this.userService.userBranchSerial }).pipe(
            tap(
              (demp) => (this.newEmployer ||= !demp || demp?.length === 0 || false)
            ))
        )
      )
    );

  private _branchSerial: string;
  private _addnewRequested = false;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private branchEmployersService: BranchEmployerServiceNew,
    public updateEmployerService: BranchEmployerService,
    private isLoadingService: IsLoadingService
  ) { }

  // get employerListFilter(): string {
  //   return this._employerListFilter;
  // }

  // set employerListFilter(value: string) {
  //   this._employerListFilter = value;
  //   this.AllfilteredEmployers = this.employerListFilter
  //     ? this.searchEmployersByName(this.employerListFilter)
  //     : this.bEmployers;
  // }
  ngOnInit() {
    this._branchSerial = this.userService.userBranchSerial;

    this.selectedLst2 = {
      id: 0,
      employerName: this.searchValue,
      tnumber: 0,
      name: this.searchValue
    };
    const subscription = this.employerNameSubject.subscribe((currentValue) => {
      this.textChange.emit(currentValue);
      this.newEmployer = true;
    });
    this.subscriptions.push(subscription);

    // Note, because `IsLoadingService#isLoading$()` returns
    // a new observable each time it is called, it shouldn't
    // be called directly inside a component template.
    this.quickSEarchIsPending$ = this.isLoadingService.isLoading$();

    // this.router.events
    //   .pipe(
    //     filter(
    //       event =>
    //         event instanceof NavigationStart ||
    //         event instanceof NavigationEnd ||
    //         event instanceof NavigationCancel ||
    //         event instanceof NavigationError,
    //     ),
    //   )
    //   .subscribe(event => {
    //     // If it's the start of navigation, `add()` a loading indicator
    //     if (event instanceof NavigationStart) {
    //       this.isLoadingService.add();
    //       return;
    //     }

    //     // Else navigation has ended, so `remove()` a loading indicator
    //     this.isLoadingService.remove();
    //   });

    //this.loadData();

    //this.createForm();


    //this.saveForm();
    //this.employersearchFormGroup.get('branchEmployers')?.valueChanges.pipe(
    //   filter(value => value),
    //   debounceTime(1000),
    //  map((value) => this.quickSearch(value)));
    // ).subscribe(listing => listing);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && !changes.searchValue.firstChange) {
      if (changes.searchValue.currentValue != '' && changes.searchValue.currentValue.includes(',')) {
        // this.selectedLst2 = {
        //   id: 0,
        //   employerName: changes.searchValue.currentValue,
        //   tnumber: 0,
        //   name: changes.searchValue.currentValue
        // };


        this.selectedLst2 = {
          id: changes.searchValue.currentValue.split(',')[1],
          employerName: changes.searchValue.currentValue.split(',')[0],
          name: changes.searchValue.currentValue.split(',')[0],
          tnumber: changes.searchValue.currentValue.split(',')[1]
        };
        const searvTExt = document.querySelector("#searchValue");
        if (searvTExt) {
          (searvTExt as HTMLInputElement).value = changes.searchValue.currentValue.split(',')[0];
        }

        if (changes.searchValue.currentValue == 'SASSA,0') {
          (searvTExt as HTMLInputElement).value = '';
        }
      }
    }
  }

  private createForm() {
    //   if (!this.employerDetails) this.employerDetails = { 'status': 'NEW' } as EmployerDetailsModel;

    //   let status: string = this.employerDetails.status ? 'true' : 'false';

    this.employersearchFormGroup = this.fb.group({
      employer: ['', [Validators.required, Validators.pattern('^[a-zA-Z -]*$')]],
      //     // 'email': [this.employer.email, [Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")],
      //     // [this.emailExistsValidator()],
      //     //   'blur'
      //     // ],
      //     //'source': [this.contact.source, [Validators.required]],
      //     //'sourceDetails': [this.contact.sourceDetails, [Validators.pattern('^[a-zA-Z0-9 \-\]*$')]],
      //     'status': [this.employerDetails.status, [Validators.required]],
      selemployerName: [this.branchEmployers, Validators.required, Validators.pattern('^[a-zA-Z., -]*$')]
      //     //'lineOfBusiness': [this.contact.linesOfBusiness],
      //     //'authority': [authority],
      //     //'title': [this.contact.title, [Validators.pattern('^[a-zA-Z \-\]*$')]],
      // 'branchEmployer': [this.branchEmployers,
      // [this.employerExistsValidator()],
      //   'blur',
      // Validators.required, Validators.pattern('^[a-zA-Z., \-\]*$')]
    });
  }

  // searchBranchEmployers(event: Event) {
  //   this.selectedLst = { employerName: '', id: -1, email: '', paydate: 4, shiftingRule: '', tel1: '', wageFrequency: '' };
  //   if (this._addnewRequested) {
  //     this._addnewRequested = false;
  //     //this.loadBranchEmployers();
  //   }
  //   this.employerNameSubject.next((event.target as HTMLTextAreaElement).value);
  //   //this.employerNameSubject.next(this.searchValue);
  // }

  searchEmployersByName(filterValue: string) {
    filterValue = filterValue.replace(" ", "").toLowerCase();
    return this.bEmployers.filter(
      (employer) =>
        employer.employerName
          .replace(" ", "")
          .toLowerCase()
          .indexOf(filterValue) !== -1
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  // Push a search term into the observable stream.
  quickSearch(searchElement: any) {
    if (searchElement.value.length >= 1) {
      const branchemployer = searchElement.value;
      this.selectedLst2 = {
        id: 0,
        employerName: branchemployer,
        tnumber: 0,
        name: branchemployer
      };

      this.employerNameSubject.next(searchElement.value);
    }
  }



  onAddNewClick() {
    this._addnewRequested = true;
    this.addnewRequired.emit(this._addnewRequested);
  }

  employerSelected(empitem?: any) {
    if (!empitem) {
      this.empSelected.emit({
        employerName: this.searchValue,
        id: 0,
        name: this.searchValue,
        tnumber: 0
      });
      return;
    }


    if (empitem && empitem.id === -99) {
      this.addnewRequired.emit(true);
      return;
    }
    const searvTExt = document.querySelector("#searchValue");
    if (searvTExt) {
      (searvTExt as HTMLInputElement).value = empitem.name;
    }
    this.inputwdith = 200;
    this.newEmployer = false;
    this.selectedLst2 = {
      id: empitem.tnumber,
      employerName: empitem.name,
      name: empitem.name,
      tnumber: empitem.tnumber
    };
    this.empSelected.emit(this.selectedLst2);
  }

  private filterEmployer(name: string): BranchEmployer[] {
    //const filterValue = name.toUpperCase(); //now done by operator
    this.bEmployers = this.branchEmployers.filter(
      (branchEmp) => branchEmp.employerName === name
    );
    this.newEmployer = !(this.bEmployers && this.bEmployers?.length >= 0) || false;

    // this reduces the input to add the Add new iconbutton
    this.inputwdith = this.newEmployer ? 165 : 200;
    return this.bEmployers;
  }

  private saveForm() {
    this.originalFormState = this.employersearchFormGroup.getRawValue();
  }

  // private employerExistsValidator(): ValidatorFn {
  //   return (control: AbstractControl): { [key: string]: boolean; } | null => {
  //     if (!this.employerDetails.id) {
  //       //if we get here it's a new contact
  //     } else {
  //       //       //if the user has an ID, that means a duplicate email is okay - it could be the
  //       //       //user's original email address
  //       //       if (this.originalFormState && this.originalFormState.email === control.value) {
  //       if (this.originalFormState && this.originalFormState.employerTNR === control.value) {
  //         //       //if we get here the user has the same email address as always - no conflict
  //         return of(null);
  //       } else {
  //         //         //the user changed the email address - need to check
  //         //         return this.checkEmail(control);
  //       }
  //     }
  //     if (control.value === "Loading...") {
  //       return { invalid: true };
  //     }

  //     this.getEmployer(control.value);
  //     return null;
  //   };
  // }

  // private checkEmployer(
  //   control: AbstractControl
  // ): Observable<ValidationErrors | null> {
  //   if (control.value && (<string>control.value).trim().length > 0) {
  //     return of(control.value).pipe(
  //       delay(500),
  //       switchMap((email) =>
  //         this.branchEmployersService
  //           .doesEmailExist(email)
  //           .pipe(
  //             map((emailExists) => (emailExists ? { emailExists: true } : null))
  //           )
  //       )
  //     );
  //   } else {
  //     return of(null);
  //   }
  // }

  // private emailExistsValidator(): ValidatorFn {
  //   return (control: AbstractControl): Observable<ValidationErrors | null> => {
  //     if (!this.employerDetails.id) {
  //       //if we get here it's a new contact
  //       return this.checkEmail(control);
  //     } else {
  //       //if the user has an ID, that means a duplicate email is okay - it could be the
  //       //user's original email address
  //       if (this.originalFormState && this.originalFormState.email === control.value) {
  //       //if we get here the user has the same email address as always - no conflict
  //         return of(null);
  //       } else {
  //         //the user changed the email address - need to check
  //         return this.checkEmail(control);
  //       }
  //     }
  //   };
  // }

  // private checkEmail(
  //   control: AbstractControl
  // ): Observable<ValidationErrors | null> {
  //   if (control.value && (<string>control.value).trim().length > 0) {
  //     return of(control.value).pipe(
  //       delay(500),
  //       switchMap((email) =>
  //         // this.branchEmployersService
  //         //   .doesEmailExist(email)
  //         //   .pipe(
  //         //     map((emailExists) => (emailExists ? { emailExists: true } : null))
  //         //   )
  //       )
  //     );
  //   } else {
  //     return of(null);
  //   }
  // }



  // private getEmployer(eName: string): BranchEmployer[] | undefined {
  //   let branchEmp: BranchEmployer[] = [
  //     {
  //       employerName: eName,
  //       name: eName,
  //       tnumber: 0,
  //       id: -1,
  //       email: "",
  //       paydate: 4,
  //       shiftingRule: "",
  //       tel1: "",
  //       wageFrequency: ""
  //     }
  //   ];

  //   if (!eName) {
  //     return branchEmp;
  //   }

  //   this.branchEmployersService.quickSearchBranchEmployers({ eName: eName, bserial: this._branchSerial })
  //     .subscribe({
  //       next: (qsResults) => {
  //         return { qsResults };
  //       }
  //     });

  //   // const ell = this.branchEmployers.filter(
  //   //   (o) => o.employerName.indexOf(eName) === 0
  //   // ); // && filters.city.includes(o.city));
  //   // return ell[0] || null;
  //   //return branchEmp;
  // }
}
