export const environment = {
  apiUrl: 'https://fb-ulms.aakil.net',
  apiDocUrl: 'https://fb-ulms.aakil.net',
  apiBioUrl: 'https://fb-ulms.aakil.net',
  keycloakHost: 'https://fb-ukc.aakil.net',
  enableDebug: true,
  production: false,
  showtrace: true,
  dummy_JSONs: false,
  showClientNotes: false,
  name: 'UAT'
};
